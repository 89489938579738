export const redirects = [
  {
    path: '/help/terms-and-privacy/service-agreement',
    redirect: { name: 'terms-of-service' },
  },
  {
    path: '/help/terms-and-privacy/privacy-policy.htm',
    redirect: { name: 'privacy-policy' },
  },
  {
    path: '/help/terms-and-privacy/service-agreement.htm',
    redirect: { name: 'terms-of-service' },
  },
  {
    path: '/help/terms-and-privacy/consumer-rights.htm',
    redirect: { name: 'consumer-rights' },
  },
]
