<script setup>
const router = useRouter()
const siteStore = useSiteStore()

const cookieBannerOpen = ref()

router.beforeEach(() => {
  if (!import.meta.env.SSR) {
    cookieBannerOpen.value = !localStorage.getItem(
      'tls-cookie-disclaimer-alert'
    )
  }
})

onMounted(() => {
  if (!import.meta.env.SSR) {
    cookieBannerOpen.value = !localStorage.getItem(
      'tls-cookie-disclaimer-alert'
    )
  }
})
</script>

<template>
  <a
    id="tls-accessibility-button"
    type="button"
    :class="{
      '!bottom-1rem !left-1rem': cookieBannerOpen,
      'member-nav-collapsed': siteStore.collapsedMenu,
    }"
    class="fixed bottom-1rem left-1rem z-1005 bg-primary rounded-full p-0.1rem w-2.5rem h-2.5rem flex items-center justify-center text-white shadow-md focus:outline-none focus:ring-5 focus:ring-text-secondary transition-all hover:scale-105 hover:cursor-pointer"
    title="Accessibility Menu"
    tabindex="0"
  >
    <i class="i-carbon-accessibility-alt w-full h-full"></i>
    <span class="sr-only">Accessibility Menu</span>
  </a>
</template>
