<template>
  <main class="w-full h-full flex items-center justify-center">
    <div class="flex flex-col items-center">
      <dotlottie-player
        src="/lotties/loading.lottie"
        speed="1"
        style="width: 240px; height: 240px"
        direction="1"
        mode="normal"
        loop
        autoplay
      ></dotlottie-player>
    </div>
  </main>
</template>
