import { useMemberFetch } from '~/services/member.service'
import mockCreditResp from '../../mocks/TULite_3b.json'
import mockReportsList from '../../mocks/reportsList.json'
import { $i18n } from '~/modules/i18n'

export const useCreditStore = defineStore('credit', () => {
  const siteStore = useSiteStore()
  const route = useRoute()
  const { t } = $i18n

  /**
   * State Definitions
   */

  const reportError = ref<boolean>(false)
  const reportLoading = ref<boolean>(false)
  const creditReport = ref<any>({})
  const reportsList = ref<any>([])
  const lastViewedReport = ref<string>(
    localStorage?.getItem('tls-lastviewed-report') || '3b-smart-report'
  )
  const currentOrderId = ref<string>(
    route.query?.orderId ? (route.query.orderId as string) : 'current'
  )

  /**
   * Getters
   */

  const getReportsList = computed(() => reportsList.value)

  const getCreditReportTradeline = computed(() =>
    creditReport.value[currentOrderId.value]
      ? creditReport.value[currentOrderId.value]
      : []
  )

  const getCreditReportMerge = computed(
    () =>
      getCreditReportTradeline.value.find((x: any) => {
        if (x.Type === 'MergeCreditReports') return x
      })?.TrueLinkCreditReportType
  )

  /**
   * Actions
   */

  const getCreditReportByBureau = (bureau: string): any =>
    getCreditReportTradeline.value.find((x: any) => {
      if (
        x?.CreditScoreType?.Source?.Bureau?.abbreviation.toUpperCase() == bureau
      ) {
        return x
      }
    })

  const getCreditScoreByBureau = (bureau: string): number | null =>
    getCreditReportByBureau(bureau)?.CreditScoreType?.riskScore

  const getCreditReportDateByBureau = (bureau: string): number | null =>
    getCreditReportByBureau(bureau)?.CreditScoreType?.Source?.InquiryDate

  const getCreditScoreRatingByBureau = (bureau: string): number | null =>
    getCreditReportByBureau(bureau)?.CreditScoreType?.CreditScoreRating
      ?.description

  const fetchReportsList = async () => {
    if (reportsList.value.length) return

    if (import.meta.env.VITE_APP_USE_MOCK === 'true') {
      reportsList.value = mockReportsList.orders
      return
    }

    try {
      const { error, data } = await useMemberFetch('credit/3bs/metadata')

      if (data.value && !error.value) {
        reportsList.value = data.value.orders
        reportError.value = false
      }
    } catch (error) {
      reportError.value = true
      return false
    } finally {
      reportLoading.value = false
      return false
    }
  }

  const getCreditReport = async () => {
    if (creditReport.value[currentOrderId.value]?.length || reportLoading.value)
      return

    reportLoading.value = true

    await fetchReportsList()

    if (!import.meta.env.SSR && localStorage.getItem('test_3b_json')) {
      try {
        creditReport.value[currentOrderId.value] = await JSON.parse(
          localStorage.getItem('test_3b_json')
        ).BundleComponents.BundleComponent
      } catch (err) {
        siteStore.setError(t('errors.CREDIT_RETRIEVAL_FAILURE'))
      } finally {
        reportLoading.value = false
        return
      }
    }

    if (import.meta.env.VITE_APP_USE_MOCK === 'true') {
      console.log('Fetching credit report')
      await siteStore.waitTimer(500)
      console.log('Credit report found')
      creditReport.value[currentOrderId.value] =
        mockCreditResp.BundleComponents.BundleComponent
      reportLoading.value = false
      return
    }

    try {
      const { error, data } = await useMemberFetch(
        `credit/3bs/${currentOrderId.value}`
      )

      if (data.value && !error.value) {
        creditReport.value[currentOrderId.value] =
          data.value?.BundleComponents?.BundleComponent
        reportError.value = false
      } else {
        reportError.value = true
      }
    } catch (error) {
      reportError.value = true
      return false
    } finally {
      reportLoading.value = false
    }
  }

  const $reset = () => {
    creditReport.value = []
    reportsList.value = []
    reportError.value = false
  }

  return {
    reportError,
    creditReport,
    lastViewedReport,
    currentOrderId,
    getCreditReportMerge,
    getCreditScoreByBureau,
    getCreditReportDateByBureau,
    getCreditScoreRatingByBureau,
    getCreditReport,
    getReportsList,
    $reset,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useCreditStore, import.meta.hot))
