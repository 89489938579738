import * as Sentry from '@sentry/vue'

const sentryEnabled = () =>
  import.meta.env.VITE_APP_SENTRY_DSN &&
  !import.meta.env.SSR &&
  Sentry.isInitialized()

export function installSentry(app) {
  if (
    import.meta.env.VITE_APP_SENTRY_DSN &&
    !import.meta.env.SSR &&
    !Sentry.isInitialized()
  ) {
    Sentry.init({
      app,
      dsn: import.meta.env.VITE_APP_SENTRY_DSN,
      environment: import.meta.env.MODE,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
      ],
      release: `thelendingscore@${import.meta.env.npm_package_version}`,
      // Performance Monitoring
      tracesSampleRate: import.meta.env.VITE_SENTRY_SAMPLE_RATE,
      // Session Replay
      replaysSessionSampleRate: import.meta.env
        .VITE_SENTRY_SESSION_REPLAY_SAMPLE_RATE,
      replaysOnErrorSampleRate: 1.0,
    })

    initResourceFailedListener()
  }
}

export function captureMessage(message, level = 'info') {
  if (sentryEnabled()) Sentry.captureMessage(message, level)
}

export function captureException(error) {
  if (sentryEnabled()) Sentry.captureException(error)
}

// Used to capture 404 responses for resources
function initResourceFailedListener() {
  document.body.addEventListener(
    'error',
    (event) => {
      if (!event.target) return

      if (event.target.tagName === 'IMG') {
        Sentry.captureMessage(
          `Failed to load image: ${event.target.src}`,
          'warning'
        )
      } else if (event.target.tagName === 'LINK') {
        Sentry.captureMessage(
          `Failed to load css: ${event.target.href}`,
          'warning'
        )
      } else if (event.target.tagName === 'SCRIPT') {
        Sentry.captureMessage(
          `Failed to load script: ${event.target.src}`,
          'warning'
        )
      }
    },
    true // useCapture - necessary for resource loading errors
  )
}

export const install: AppModule = ({ isClient, initialState, app }) => {
  installSentry(app)
}
