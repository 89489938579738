import { $analytics, segmentEnabled } from '~/modules/asegment'
import { datalayerPush } from '~/modules/gtm'
import { useMemberFetch } from '~/services/member.service'

import type {
  userType,
  creditCardType,
  billingHistoryType,
  memberPlanType,
  newCreditCardType,
} from '~/types'
import mockUserResp from '../../mocks/user.json'
import mockBillingHistoryResp from '../../mocks/billing.json'
import mockCreditCardResp from '../../mocks/creditcard-current.json'

export const useUserStore = defineStore('user', () => {
  const siteStore = useSiteStore()
  const signupStore = useSignupStore()

  /**
   * State Definitions
   */

  const user = ref<userType | null>()
  const userPID = ref<string>('')
  const accountStatus = ref<string>('')
  const closedPendingDate = ref<string>('')
  const billingHistory = ref<billingHistoryType | null>()
  const memberPlan = ref<memberPlanType | null>()
  const currentCreditCard = ref<creditCardType | null>()

  /**
   * Getters
   */

  const userNameInitial = computed(() =>
    user.value
      ? `${user.value?.name.firstName.slice(0, 1).toUpperCase()}`
      : null
  )

  const userName = computed(() =>
    user.value
      ? `${user.value?.name.firstName} ${user.value?.name.lastName}`
      : null
  )

  const userAddress = computed(() =>
    user.value
      ? `${user.value?.homeAddress.street}, ${user.value?.homeAddress.city}, ${user.value?.homeAddress.state} ${user.value?.homeAddress.zip}`
      : null
  )

  const userCustomerToken = computed(() => {
    return user.value?.customerToken
  })

  const getIncluded3BReportsPerMonth = computed(() =>
    memberPlan.value
      ? memberPlan.value.included3BReportsPerMonth
      : signupStore.getSelectedMemberPlan?.included3BReportsPerMonth
  )

  const getChargeMonthly = computed(() =>
    memberPlan.value
      ? memberPlan.value.planType === 'SPONSORED'
        ? '0'
        : memberPlan.value.chargeDisplay
      : signupStore.getSelectedMemberPlan?.chargeDisplay
  )

  const getTrialLength = computed(() =>
    memberPlan.value
      ? memberPlan.value.trialLength
      : signupStore.getSelectedMemberPlan?.trialLength
  )

  const getPlanType = computed(() =>
    memberPlan.value
      ? memberPlan.value.planType
      : signupStore.getSelectedMemberPlan?.planType
  )

  const getChargeSetup = computed(() =>
    memberPlan.value
      ? memberPlan.value.chargeSetup
      : signupStore.getSelectedMemberPlan?.chargeSetup
  )

  /**
   * Actions
   */

  const clearUserPID = () => {
    userPID.value = ''
    if (!import.meta.env.SSR) localStorage.removeItem('PID')
  }

  const setUser = (payload: any) => {
    if (payload?.pid) userPID.value = payload.pid
    user.value = {
      ...user.value,
      ...payload,
    }
  }

  const getUser = async () => {
    if (user.value) return true

    if (import.meta.env.VITE_APP_USE_MOCK === 'true') {
      console.log('Fetching user details')
      await siteStore.waitTimer(500)

      setUser(mockUserResp)

      console.log('User details saved')
      return true
    }

    try {
      const { error, data } = await useMemberFetch('customer')

      if (data.value && !error.value) {
        const trackingStore = useTrackingStore()

        setUser(data.value)

        await getAccountStatus()
        await getAccountMemberPlan()

        if (segmentEnabled.value) {
          $analytics.identify(user.value!.customerToken, {
            email: user.value!.email,
            status: accountStatus.value.toUpperCase(),
            ...(closedPendingDate.value && {
              closeDate: closedPendingDate.value,
            }),
            ...trackingStore.segmentTrackingData,
          })
        }

        datalayerPush({
          user_id: user.value!.customerToken,
          customerEmail: user.value!.email,
          customerFirstName: user.value!.name?.firstName,
          customerLastName: user.value!.name?.lastName,
          ...trackingStore.gtmTrackingData,
        })

        return true
      }

      return false
    } catch (error) {
      return false
    }
  }

  const saveUser = async (payload: any) => {
    try {
      const { statusCode } = await useMemberFetch('customer').put(
        JSON.stringify(payload)
      )

      if (statusCode.value?.toString().startsWith('2')) {
        setUser(payload)
        return true
      }

      return false
    } catch (error) {
      return false
    }
  }

  const getBillingHistory = async () => {
    if (billingHistory.value) return true

    if (import.meta.env.VITE_APP_USE_MOCK === 'true') {
      await siteStore.waitTimer(500)

      billingHistory.value = mockBillingHistoryResp

      return true
    }

    try {
      const { error, data } = await useMemberFetch(
        'customer/billing/transactions'
      )

      if (data.value && !error.value) {
        billingHistory.value = data.value
        return true
      }

      return false
    } catch (error) {
      return false
    }
  }

  const getCreditCards = async () => {
    if (currentCreditCard.value) return true

    if (import.meta.env.VITE_APP_USE_MOCK === 'true') {
      await siteStore.waitTimer(500)

      currentCreditCard.value = mockCreditCardResp

      return currentCreditCard.value
    }

    try {
      const { error, data } = await useMemberFetch(
        'customer/billing/credit-cards/current'
      )

      if (data.value && !error.value) {
        currentCreditCard.value = data.value
        return currentCreditCard.value
      }

      return false
    } catch (error) {
      return false
    }
  }

  const saveCreditCard = async (payload: newCreditCardType) => {
    try {
      const { error, data } = await useMemberFetch(
        'customer/billing/credit-cards'
      ).post(JSON.stringify(payload))

      if (data.value && !error.value) {
        currentCreditCard.value = data.value
        return true
      }

      return false
    } catch (error) {
      return false
    }
  }

  const getAccountStatus = async () => {
    try {
      const { error, data } = await useMemberFetch('customer/status')

      if (data.value && !error.value) {
        accountStatus.value = data.value.accountStatus.toLowerCase()
        if (data.value.isClosePending)
          closedPendingDate.value = data.value.closeDate
        return true
      }

      return false
    } catch (error) {
      return false
    }
  }

  const changeAccountStatus = async (status: string) => {
    try {
      const { statusCode, data } = await useMemberFetch('customer/status').put(
        JSON.stringify({ status })
      )

      if (statusCode.value?.toString().startsWith('2')) {
        if (status === 'ACTIVE') {
          closedPendingDate.value = ''
        } else {
          closedPendingDate.value = data.value.closeDate
        }
        return true
      }

      return false
    } catch (error) {
      return false
    }
  }

  const getAccountMemberPlan = async () => {
    try {
      const { error, data } = await useMemberFetch('customer/member-plan')

      if (data.value && !error.value) {
        memberPlan.value = data.value
        return true
      }

      return false
    } catch (error) {
      return false
    }
  }

  const $reset = () => {
    user.value = undefined
  }

  return {
    user,
    userPID,
    userName,
    userNameInitial,
    userAddress,
    userCustomerToken,
    accountStatus,
    closedPendingDate,
    billingHistory,
    currentCreditCard,
    memberPlan,
    getIncluded3BReportsPerMonth,
    getChargeMonthly,
    getChargeSetup,
    getPlanType,
    getTrialLength,
    clearUserPID,
    getUser,
    setUser,
    saveUser,
    getBillingHistory,
    getCreditCards,
    saveCreditCard,
    getAccountStatus,
    changeAccountStatus,
    $reset,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot))
