import { gtmEnabled, gtmId } from '~/composables'

export const install = ({ isClient }) => {
  // GTM plugin enabled in env

  if (isClient && gtmEnabled && !hasScript()) loadScript()
}
/**
 * Load GTM script tag
 * @param {String}  id  GTM ID
 * @param {Object}  params query params object
 */
const loadScript = () => {
  const doc = document,
    script = doc.createElement('script')

  window.dataLayer = window.dataLayer || []

  window.dataLayer.push({
    event: 'gtm.js',
    'gtm.start': new Date().getTime(),
  })

  if (!gtmId) {
    return
  }

  script.async = true
  script.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}`
  doc.head.appendChild(script)
}

/**
 * Check if GTM script is in the document
 * @return {boolean}
 */
const hasScript = function () {
  return Array.from(document.getElementsByTagName('script')).some((script) =>
    script.src.includes('googletagmanager.com/gtm.js')
  )
}

export const datalayerPush = (data) => {
  if (gtmEnabled) {
    window.dataLayer.push(data)
  }
}
