<script setup lang="ts">
import { $analytics, segmentEnabled } from '~/modules/asegment'

const trackingStore = useTrackingStore()
const signupStore = useSignupStore()
const siteStore = useSiteStore()
const router = useRouter()
const route = useRoute()

const { t } = useI18n()

if (!import.meta.env.SSR) {
  import('@dotlottie/player-component')
}

const loading = ref(true)

onBeforeMount(async () => {
  const state = router.currentRoute.value.query?.state
  if (state) {
    if (state.includes('ERROR')) {
      siteStore.setError(t(`errors.${state}`))
      router.replace({ query: undefined })
    } else if (state === 'LOGOUT_SUCCESS') {
      window.location.href = `${siteStore.accountLogoutUrl}&redirect_uri=${
        import.meta.env.VITE_APP_TLS_URL
      }&state=LOGOUT_SUCCESS_COMPLETE`
    } else if (state === 'LOGOUT_SUCCESS_COMPLETE') {
      siteStore.setSuccess(t('public.logoutSuccess'))
      router.replace({ query: undefined })
    }
  }

  if (Object.keys(route.query).length) {
    const trackingParamsExist = await trackingStore.setTrackingData(route.query)
    if (trackingParamsExist) router.replace({ query: undefined })
  }

  await signupStore.getCampaign()

  loading.value = false
})

onMounted(() => {
  const trackingStore = useTrackingStore()
  trackingStore.startTracking()

  if (segmentEnabled.value && router.currentRoute.value.name !== 'auth') {
    $analytics.page(router.currentRoute.value.fullPath, {
      ...trackingStore.segmentTrackingData,
    })
  }
})
</script>

<template>
  <router-view v-slot="{ Component }">
    <transition name="fade" mode="out-in">
      <component :is="Component" v-if="siteStore.localeReady && !loading" />
      <LoadingScreen v-else />
    </transition>
  </router-view>
  <AccessibilityButton />
</template>
