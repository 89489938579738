import { useMemberFetch } from '~/services/member.service'
import { trackingParamsType } from '~/types'
import { $i18n } from '~/modules/i18n'
import mylonaTracking from '../../mocks/mylonaTracking.json'
import { $analytics, segmentEnabled } from '~/modules/asegment'

export const useTrackingStore = defineStore('tracking', () => {
  const userStore = useUserStore()

  const supportedTokens = [
    'pid',
    'sid',
    'tid',
    'aid',
    'adid',
    'sourceid',
    'cid',
  ]

  const urlParams = ref()
  const mylonaSessionGuid = ref('')
  const mylonaCustomerToken = ref()

  const trackingParams = ref<trackingParamsType | object>({})

  /**
   * Returns an object constructed for segment properties
   *
   * @computed
   */
  const segmentTrackingData = computed(() => {
    return {
      company: 'Consumer Direct',
      product_code: import.meta.env.VITE_APP_PRODUCT_CODE,
      publisher_idn: userStore.userPID,
      language: $i18n.locale.value,
      plan_type: userStore.getPlanType,
      ...(trackingParams.value?.AID && {
        affiliate_id: trackingParams.value.AID,
      }),
      ...(trackingParams.value?.ADID && {
        ad_creative_id: trackingParams.value.ADID,
      }),
      ...(trackingParams.value?.CID && {
        campaign_id: trackingParams.value.CID,
      }),
      ...(trackingParams.value?.SID && { site_id: trackingParams.value.SID }),
      ...(trackingParams.value?.TID && {
        template_id: trackingParams.value.TID,
      }),
    }
  })

  const gtmTrackingData = computed(() => {
    return {
      event: 'set-tracking-ids', // event name
      product_code: import.meta.env.VITE_APP_PRODUCT_CODE,
      publisher_id: userStore.userPID,

      ...(trackingParams.value?.AID && {
        affiliate_id: trackingParams.value.AID,
      }),
      ...(trackingParams.value?.ADID && {
        ad_creative_id: trackingParams.value.ADID,
      }),
      ...(trackingParams.value?.CID && {
        campaign_id: trackingParams.value.CID,
      }),
      ...(trackingParams.value?.SID && { site_id: trackingParams.value.SID }),
      ...(trackingParams.value?.TID && {
        tid: trackingParams.value.TID,
      }),
      plan: userStore.getPlanType,
    }
  })

  const saveTrackingParam = (param: string, val: string): void => {
    if (param === 'PID') {
      if (val.match(/\d{5}/g)) {
        userStore.userPID = val
        if (!import.meta.env.SSR) localStorage.setItem('PID', val)
      }
      return
    }

    trackingParams.value = {
      ...{ [param]: val },
      ...trackingParams.value,
    }
  }

  const setTrackingData = async (params: LocationQuery) =>
    new Promise<boolean>((resolve) => {
      let trackingParamsExist = false
      urlParams.value = params

      Object.keys(urlParams.value).forEach((p) => {
        const token = p.toLowerCase()
        if (supportedTokens.findIndex((x) => x === token) !== -1) {
          trackingParamsExist = true
          saveTrackingParam(token.toUpperCase(), encodeURI(urlParams.value[p]))
        }
      })

      resolve(trackingParamsExist)
    })

  const startSupermoneyTrack = async (category: string): Promise<boolean> => {
    if (import.meta.env.VITE_APP_USE_MOCK === 'true') {
      mylonaSessionGuid.value = mylonaTracking.sessionGuid
      mylonaCustomerToken.value = mylonaTracking.customerToken
      return true
    }

    try {
      const { error, data } = await useMemberFetch('mylona/tracking').post(
        JSON.stringify({ category })
      )

      if (data.value && !error.value) {
        mylonaSessionGuid.value = data.value?.sessionGuid
        mylonaCustomerToken.value = data.value?.customerToken
        return true
      } else return false
    } catch (error) {
      return false
    }
  }

  const startTracking = () => {
    const router = useRouter()

    router.afterEach((to) => {
      if (to && segmentEnabled.value && to.name !== 'auth') {
        $analytics.page(to.fullPath, {
          ...segmentTrackingData.value,
        })
      }
    })
  }

  const $reset = () => {
    trackingParams.value = {}
  }

  return {
    trackingParams,
    segmentTrackingData,
    gtmTrackingData,
    mylonaCustomerToken,
    mylonaSessionGuid,
    startTracking,
    setTrackingData,
    saveTrackingParam,
    startSupermoneyTrack,
    $reset,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useTrackingStore, import.meta.hot))
